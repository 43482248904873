import { fetch } from 'utils/fetch';

export const uploadFile = async (params) => {
  const files = await Promise.all(
    Object.entries(params.data)
      .filter(
        ([_, value]: any) =>
          value && value.rawFile && value.rawFile instanceof File
      )
      .map(async ([key, value]: any) => {
        const formData = new FormData();

        formData.append('file', value.rawFile);

        const { data } = await fetch(`/files`, {
          method: 'POST',
          body: formData,
        });

        return {
          [key]: data[0].url,
        };
      })
  );

  return {
    files: files.reduce(
      (acc, item) => ({
        ...acc,
        ...item,
      }),
      {}
    ),
  };
};
