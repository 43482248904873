import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  cardChip: {
    backgroundColor: (props) =>
      !!props && !!props.labelBackgroundColor && props.labelBackgroundColor,
    color: (props) => !!props && !!props.labelColor && props.labelColor,
    marginBottom: 8,
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 4,
  },
} as any);
