import React from 'react';
import { Typography } from '@material-ui/core';
import {
  List as RaList,
  Datagrid,
  DateField,
  TextField,
  NumberField,
  ReferenceField,
  FunctionField,
} from 'react-admin';

import { defaultCurrencyOptions } from 'constants/currencies';
import { dateTimeOptions } from 'constants/dates';
import { OrderStatusField } from 'components/Fields/OrderStatus';
import { paymentPlatforms } from 'constants/payments';

import { useStyles } from './styles';

export const LastOrders = ({ isLoading, fromRef, toRef }) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        align="left"
        variant="h6"
        className={classes.title}
        gutterBottom
        paragraph
      >
        Últimos pedidos
      </Typography>
      <RaList
        title="Overview"
        basePath="/"
        resource="orders"
        bulkActionButtons={false}
        actions={null}
        sort={{ field: 'created_at', order: 'DESC' }}
        filter={{
          from: fromRef.current,
          to: toRef.current,
          order_status: {
            name: 'succeeded',
          },
        }}
      >
        <Datagrid rowClick={(id) => `/orders/${id}/show`}>
          <TextField label="Número" source="number" />
          <ReferenceField label="Usuario" source="user_id" reference="users">
            <TextField source="fullname" />
          </ReferenceField>
          <ReferenceField
            label="Paquete"
            source="package.id"
            reference="packages"
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            label="Plataforma"
            render={(record) =>
              paymentPlatforms[record?.payment_platform?.name]
            }
          />
          <OrderStatusField label="Estado" />
          <NumberField
            label="Total"
            locales="es"
            source="real_price"
            options={defaultCurrencyOptions}
          />
          <DateField
            label="Fecha"
            source="created_at"
            locales="es-AR"
            options={dateTimeOptions}
          />
        </Datagrid>
      </RaList>
    </>
  );
};

LastOrders.displayName = 'LastOrders';
