import React, { useState } from 'react';
import { Notification, useDataProvider } from 'react-admin';
import { Link } from 'react-router-dom';
import { useNotify, useRedirect, NotificationType } from 'ra-core';
import { Form } from 'react-final-form';
import { lightTheme } from 'theme';
import { Button, CircularProgress, Typography, Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import { authProvider } from 'providers/authProvider';
import { Routes } from 'constants/routes';
import Logo from 'assets/logo.png';
import Layout from 'components/Layout';

import { Login } from './components/Login';
import { useStyles } from './styles';

const Auth = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [retrievePassword, setRetrievePassword] = useState(false);
  const classes = useStyles();
  const notify = useNotify();

  const onLoginClick = async (userCredentials: any) => {
    try {
      await authProvider.login(userCredentials);

      redirect(Routes.HOME);
    } catch (err) {
      console.error(err);

      const message =
        typeof err === 'string'
          ? err
          : typeof err === 'undefined' || !err.message
          ? 'ra.auth.sign_in_error'
          : err.message;

      notify(message, 'warning');
    } finally {
      setLoading(false);
    }
  };

  const onRecoverPasswordClick = async ({ email }: any) => {
    let text = null,
      type = null;

    try {
      await dataProvider.forgetPassword({ email });

      setRetrievePassword(false);

      text = 'auth.forget_password.success_message';
      type = 'info';
    } catch (err) {
      text = 'auth.forget_password.error_message';
      type = 'warning';
    } finally {
      setLoading(false);
      notify(text!, type as NotificationType);
    }
  };

  const handleSubmit = (values: any) => {
    setLoading(true);

    if (retrievePassword) {
      onRecoverPasswordClick(values);
    } else {
      onLoginClick(values);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Layout>
            <Grid container className={classes.bodyContainer}>
              <Grid item lg={10}>
                <div className={classes.headerContainer}>
                  <img alt="logo" className={classes.image} src={Logo} />
                  <Typography
                    style={{ marginTop: 16 }}
                    variant="h4"
                    component="h1"
                    align="center"
                    paragraph
                  >
                    Ingresar
                  </Typography>
                </div>
                <Login loading={loading} />
                <div className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    className={classes.submitButton}
                    onClick={handleSubmit}
                    fullWidth
                  >
                    {loading ? (
                      <CircularProgress size={25} thickness={4} />
                    ) : (
                      'Ingresar'
                    )}
                  </Button>
                  <Link to={Routes.RECOVER_PASSWORD}>
                    <Button className={classes.forgotButton}>
                      Olvidé mi contraseña
                    </Button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Layout>
          <Notification />
        </form>
      )}
    />
  );
};

export const LoginPage: React.FC<any> = (props) => (
  <ThemeProvider theme={lightTheme}>
    <Auth {...props} />
  </ThemeProvider>
);

LoginPage.displayName = 'LoginPage';
