import polyglotI18nProvider from 'ra-i18n-polyglot';

import { esMessages } from './locales/es';

const messages = {
  es: esMessages,
};

export const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  'es' // Default locale
);
