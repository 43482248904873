import { makeStyles } from '@material-ui/core';
import CoverImg from 'assets/auth-cover.jpg';

export const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: '100vh',
    background: `url(${CoverImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
}));
