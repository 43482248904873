import * as React from 'react';
import { RecoverPassword } from '../pages/RecoverPassword';
import { RouteWithoutLayout } from 'react-admin';
import { Routes } from 'constants/routes';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <RouteWithoutLayout
    exact
    path={Routes.RECOVER_PASSWORD}
    component={RecoverPassword}
    noLayout
  />,
];
