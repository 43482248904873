import firebase from 'firebase/app';
import 'firebase/auth';

export const getUser = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const currentUser = firebase.auth().currentUser;

    if (!!currentUser) {
      resolve(currentUser);
    }

    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();

      if (!!user) {
        resolve(user);
      } else {
        reject();
      }
    });
  });
};

export const getToken = async (): Promise<string | null> => {
  try {
    const currentUser = await getUser();

    return await currentUser?.getIdToken();
  } catch (err) {
    return null;
  }
};
