import React from 'react';

const properties = {
  list: React.lazy(
    () => import(/* webpackChunkName: "Sections.list" */ './List')
  ),
  create: React.lazy(
    () => import(/* webpackChunkName: "Sections.create" */ './Create')
  ),
  show: React.lazy(
    () => import(/* webpackChunkName: "Sections.show" */ './Show')
  ),
  options: {},
};

export default properties;
