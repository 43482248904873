import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: 'Inter, sans-serif',
  h1: {
    fontWeight: 'bold',
    fontSize: '2.5rem',
    lineHeight: 1.2,
  },
  h2: {
    borderBottom: '3px solid',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: 2.2,
    color: '#383C41',
    textDecoration: 'none',
  },
  h3: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: 1.3,
  },
  h6: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: 1.1,
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: 1.2,
  },
  caption: {
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '14px',
  },
};
