import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },
  submitButton: {
    marginTop: theme.spacing(),
    padding: '11px 0'
  },
  forgotButton: {
    marginTop: 24,
    textTransform: 'none',
    fontWeight: 500,
    marginLeft: '0px !important',
    padding: 0,
  },
  bodyContainer: {
    position: 'absolute',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px'
  },
  image: {
    borderRadius: '50%', 
    width: 150, 
    height: 150
  }
}));
