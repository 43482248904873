import './index.css';
import React, { Suspense, useEffect } from 'react';
import { Admin, Loading, Resource } from 'react-admin';
import { render } from 'react-dom';
import WebFont from 'webfontloader';
import { createBrowserHistory } from 'history';
import { Layout } from '@blackbox-vision/ra-layout';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import CustomRoutes from 'utils/custom-routes';
import { LoginPage } from 'pages/Auth';
import packages from 'pages/Packages';
import programs from 'pages/Programs';
import categories from 'pages/Categories';
import sections from 'pages/Sections';
import routines from 'pages/Routines';
import exercises from 'pages/Exercises';
import superSets from 'pages/SuperSets';
import requirements from 'pages/Requirements';
import packageBenefits from 'pages/PackageBenefits';
import users from 'pages/Users';
import orders from 'pages/Orders';
import tutorials from 'pages/Tutorials';
import { Dashboard } from 'pages/Dashboard';
import { dataProvider } from 'providers/dataProvider';
import { authProvider } from 'providers/authProvider';
import { i18nProvider } from 'providers/i18nProvider';
import { getMenuItems } from 'components/Menu/items';
import { lightTheme } from 'theme';

const browserHistory = createBrowserHistory();

const useStyles = makeStyles({
  sidebarWhenClosed: {
    backgroundColor: '#212121',
    paddingLeft: 0,
  },
  sidebarWhenOpen: {
    backgroundColor: '#212121',
    paddingLeft: 0,
  },
  appBar: {
    backgroundColor: '#212121',
    boxShadow: 'none',
  },
  listItem: {
    color: '#FFF',
  },
  menuLink: {
    color: '#FFF',
  },
  logoutButton: {
    position: 'absolute',
    bottom: 0,
  },
});

const App = () => {
  const classes = useStyles();

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter'],
      },
    });
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <Suspense
          fallback={
            <Loading
              loadingPrimary="Cargando..."
              loadingSecondary="Un momento por favor"
            />
          }
        >
          <Admin
            loginPage={LoginPage}
            history={browserHistory}
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            dashboard={Dashboard}
            disableTelemetry
            customRoutes={CustomRoutes}
            layout={(props: any) => {
              const menuItems = getMenuItems();

              return (
                <Layout
                  {...props}
                  items={menuItems}
                  iconStyle={{ color: '#FFF' }}
                  layoutStyles={{
                    sidebarWhenClosed: classes.sidebarWhenClosed,
                    sidebarWhenOpen: classes.sidebarWhenOpen,
                    appBar: classes.appBar,
                    listItem: classes.listItem,
                    menuLink: classes.menuLink,
                    logoutButton: classes.logoutButton,
                  }}
                />
              );
            }}
          >
            <Resource name="packages" {...packages} />
            <Resource name="super-sets" {...superSets} />
            <Resource name="exercises" {...exercises} />
            <Resource name="routines" {...routines} />
            <Resource name="sections" {...sections} />
            <Resource name="categories" {...categories} />
            <Resource name="programs" {...programs} />
            <Resource name="users" {...users} />
            <Resource name="orders" {...orders} />
            <Resource name="requirements" {...requirements} />
            <Resource name="package-benefits" {...packageBenefits} />
            <Resource name="tutorials" {...tutorials} />
            <Resource name="package-requirements" />
            <Resource name="program-categories" />
            <Resource name="program-users" />
            <Resource name="payment-platforms" />
            <Resource name="unit-types" />
            <Resource name="orders" />
            <Resource name="roles" />
            <Resource name="videos" />
          </Admin>
        </Suspense>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

render(<App />, document.getElementById('root'));
