import React from 'react';
import { XAxis, YAxis, XYPlot, VerticalBarSeries } from 'react-vis';
import { Link } from 'react-router-dom';
import currency from 'currency.js';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import {
  Avatar,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Skeleton from '@material-ui/lab/Skeleton';

import FromToDateGroup from 'components/Input/FromToDateGroup';
import { DashboardCardItem } from './components/DashboardCardItem';
import { LastOrders } from './components/LastOrders';
import { useDashboard } from './hooks';

const euro = (value) =>
  currency(value, { symbol: '€', decimal: ',', separator: '.' }).format();

export const Dashboard: React.FC<any> = (props) => {
  const { isLoading, metrics, fromRef, toRef, hasFilters } = useDashboard();

  const top3Packages = metrics?.monthly?.requested_packages;

  return (
    <Grid spacing={2} container>
      <FromToDateGroup
        fromRef={fromRef}
        toRef={toRef}
        hasFilters={hasFilters}
      />
      <Grid md={9} item>
        <Grid spacing={2} container>
          <Grid md={4} item>
            <DashboardCardItem
              label={!hasFilters ? 'Ventas hoy' : 'Total vendido'}
              labelColor="white"
              labelBackgroundColor="#077E55"
              title={euro(metrics?.daily?.sold_packages)}
              actionTitle="Total bruto"
              isLoading={isLoading}
            />
          </Grid>
          <Grid md={4} item>
            <DashboardCardItem
              label={!hasFilters ? 'Pedidos hoy' : 'Total pedidos'}
              labelColor="white"
              labelBackgroundColor="#104D9C"
              title={metrics?.daily?.requested_packages}
              actionTitle="Todos los pedidos"
              actionButton={
                <Link to="/orders">
                  <ArrowForwardIosIcon />
                </Link>
              }
              isLoading={isLoading}
            />
          </Grid>
          <Grid md={4} item>
            <DashboardCardItem
              label="Nuevos usuarios"
              labelColor="white"
              labelBackgroundColor="#F3BB25"
              title={metrics?.daily?.newer_users}
              actionTitle="Usuarios"
              actionButton={
                <Link to="/users">
                  <ArrowForwardIosIcon />
                </Link>
              }
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
        <LastOrders isLoading={isLoading} fromRef={fromRef} toRef={toRef} />
      </Grid>
      <Grid md={3} item>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom paragraph>
              {`Resumen del mes: ${format(new Date(), 'MMMM', { locale: es })}`}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Venta total del mes:
            </Typography>
            {isLoading ? (
              <Skeleton style={{ marginBottom: 8 }} height={28} width={120} />
            ) : (
              <Typography variant="subtitle1" gutterBottom>
                {euro(metrics?.monthly?.sold_packages)}
              </Typography>
            )}
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Venta total de los últimos 4 meses (EUR):
            </Typography>
            {isLoading ? (
              <Skeleton
                style={{ marginTop: 24, marginBottom: 24 }}
                height={200}
                variant="rect"
              />
            ) : (
              <div style={{ marginTop: 24, marginBottom: 24 }}>
                <XYPlot xType="ordinal" stackBy="y" height={250} width={250}>
                  <XAxis />
                  <YAxis tickSize={-8} />
                  <VerticalBarSeries
                    color="#104D9C"
                    barWidth={0.5}
                    data={metrics?.monthly?.comparison}
                  />
                </XYPlot>
              </div>
            )}
            <Typography variant="subtitle1" color="textSecondary">
              Pedidos por producto
            </Typography>
            <List>
              {isLoading ? (
                <Grid spacing={2} container>
                  <Grid xs={12} item>
                    <Skeleton variant="rect" height={60} />
                  </Grid>
                  <Grid xs={12} item>
                    <Skeleton variant="rect" height={60} />
                  </Grid>
                  <Grid xs={12} item>
                    <Skeleton variant="rect" height={60} />
                  </Grid>
                </Grid>
              ) : top3Packages?.length > 0 ? (
                top3Packages?.map((pkg: any) => (
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar variant="square" src={pkg?.cover_url} />
                    </ListItemAvatar>
                    <ListItemText primary={pkg?.name} secondary={pkg?.count} />
                  </ListItem>
                ))
              ) : (
                <Typography variant="subtitle2">No hay resultados</Typography>
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

Dashboard.displayName = 'Dashboard';
