import { fetchUtils } from 'react-admin';

import * as FirebaseHelpers from 'utils/firebase';

export const fetch = async (url: string, fetchOptions?: fetchUtils.Options) => {
  const options = fetchOptions || {};

  let headers = options.headers as Headers | undefined;

  if (!headers) {
    headers = new Headers({
      Accept: 'application/json',
    });
  }

  const token = await FirebaseHelpers.getToken();

  if (!!token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  const {
    json: { meta, data, errors },
  } = await fetchUtils.fetchJson(`${process.env.REACT_APP_BASE_URL}${url}`, {
    ...options,
    headers,
  });

  if (
    options.method === 'PUT' ||
    options.method === 'POST' ||
    options.method === 'DELETE'
  ) {
    if (errors && errors.length > 0) {
      throw new Error('Hubo un error al obtener la información');
    }
  }

  return {
    meta,
    data,
    errors,
  };
};
