import React from 'react';

const properties = {
  show: React.lazy(
    () => import(/* webpackChunkName: "Tutorials.show" */ './Show')
  ),
  options: {},
};

export default properties;
