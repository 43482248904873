export const palette = {
  primary: {
    main: '#000',
    background: '#F8F8FA',
  },
  secondary: {
    light: 'rgba(16, 77, 156, 0.1)',
    main: '#F3BB25',
  },
  success: {
    main: '#4caf50',
    contrastText: '#F8F8FA',
  },
};
