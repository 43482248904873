import React, { useState } from 'react';
import { Field } from 'react-final-form';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { TextInput } from 'components/Input/Text';
import { required, email } from 'utils/validations';
import { useStyles } from './styles';

interface LoginProps {
  loading: boolean;
}

export const Login: React.FC<LoginProps> = ({ loading }) => {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Field
        autoFocus
        name="username"
        component={TextInput}
        label={'Email'}
        disabled={loading}
        className={classes.input}
        FormHelperTextProps={{
          classes: { root: classes.helperText },
        }}
        validate={email}
        variant="outlined"
      />
      <Field
        name="password"
        component={TextInput}
        label={'Contraseña'}
        disabled={loading}
        className={classes.input}
        FormHelperTextProps={{
          classes: { root: classes.helperText },
        }}
        validate={required}
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

Login.displayName = 'Login';
