import React from 'react';

const properties = {
  list: React.lazy(
    () => import(/* webpackChunkName: "Orders.list" */ './List')
  ),
  show: React.lazy(
    () => import(/* webpackChunkName: "Orders.show" */ './Show')
  ),
  options: {},
};

export default properties;
