import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DraftsIcon from '@material-ui/icons/Drafts';
import { Link } from 'react-router-dom';
import { useNotify } from 'react-admin';

import { TextInput } from 'components/Input/Text';
import { email } from 'utils/validations';
import { useStyles } from './styles';
import { authProvider } from 'providers/authProvider';
import { Routes } from 'constants/routes';
import Layout from 'components/Layout';

export const RecoverPassword = () => {
  const classes = useStyles();
  const notify = useNotify();

  const [isEmailSent, setIsEmailSent] = useState(false);

  return (
    <Form
      onSubmit={async (values) => {
        try {
          await authProvider.recoverPassword(values.email);
          setIsEmailSent(true);
        } catch (err) {
          console.error(err);
          notify(err.message, 'error');
        }
      }}
      render={({ handleSubmit }) => (
        <form>
          {isEmailSent ? (
            <Layout>
              <Grid container className={classes.bodyContainer}>
                <Grid item lg={10} className={classes.gridContainer}>
                  <DraftsIcon className={classes.emailIcon} />
                  <Typography
                    variant="h4"
                    component="h1"
                    align="center"
                    paragraph
                    className={classes.title}
                  >
                    Revisa tu correo
                  </Typography>
                  <Typography className={classes.typography}>
                    Hemos enviado un mail con las instrucciones para recuperar
                    tu contraseña
                  </Typography>
                  <Link to={Routes.LOGIN}>
                    <Button className={classes.checkLaterButton}>
                      Volver al login
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Layout>
          ) : (
            <Layout>
              <AppBar
                elevation={0}
                position="static"
                className={classes.header}
              >
                <Toolbar>
                  <Link to={Routes.LOGIN}>
                    <IconButton className={classes.icon}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Link>
                </Toolbar>
              </AppBar>
              <Grid container className={classes.bodyContainer}>
                <Grid item lg={10}>
                  <Typography
                    variant="h4"
                    component="h1"
                    align="center"
                    paragraph
                  >
                    Recuperar <br /> contraseña
                  </Typography>
                  <Typography className={classes.typography}>
                    Te enviaremos un correo de recuperación a tu email
                  </Typography>
                  <Field
                    autoFocus
                    name="email"
                    component={TextInput}
                    label={'Email'}
                    className={classes.input}
                    FormHelperTextProps={{
                      classes: { root: classes.helperText },
                    }}
                    validate={email}
                    variant="outlined"
                  />
                  <div className={classes.actions}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.submitButton}
                      onClick={handleSubmit}
                      fullWidth
                    >
                      Recuperar contraseña
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Layout>
          )}
        </form>
      )}
    />
  );
};

RecoverPassword.displayName = 'RecoverPassword';
