import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslate } from 'ra-core';

export const TextInput = ({
  meta: { touched, error },
  input: { ...inputProps },
  ...props
}: any) => {
  const translate = useTranslate();

  return (
    <TextField
      {...inputProps}
      {...props}
      error={!!(touched && error)}
      helperText={touched && error && translate(error)}
      fullWidth
    />
  );
};

TextInput.displayName = 'TextInput';
