export const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const dateTimeOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};
