import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: '1.2em',
  },
  content: {
    paddingBottom: theme.spacing(2),
  },
  helperText: {
    marginTop: '12px',
  },
}));
