import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    width: '100%',
  },
  submitButton: {
    padding: '11px 0',
  },
  checkLaterButton: {
    marginTop: 24,
    textTransform: 'none',
    fontWeight: 500,
    marginLeft: '0px !important',
    padding: 0,
  },
  input: {
    margin: '24px 0 40px',
  },
  header: {
    backgroundColor: '#FFF',
  },
  icon: {
    margin: 0,
    padding: 0,
  },
  emailIcon: {
    fontSize: 100,
    marginBottom: 24,
  },
  title: {
    margin: 0,
  },
  typography: {
    margin: '24px 0 0',
    textAlign: 'center',
  },
  bodyContainer: {
    position: 'absolute',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helperText: {
    marginTop: '12px',
  },
}));
