import set from 'lodash.set';
import { stringify } from 'qs';
import { DataProvider } from 'ra-core';

import { fetch } from 'utils/fetch';
// import { difference } from 'utils/diff';
import { uploadFile } from './uploadFile';

export const dataProvider: DataProvider = {
  getList: async (resource, params) => {
    let search = '';

    if (Object.keys(params).length > 0) {
      const { field, order } = params.sort || {};
      const { page, perPage } = params.pagination || {};

      let sort;
      let range;
      let filters = {};

      if (!!field && !!order) {
        sort = JSON.stringify([field, order]);
      }

      if (!!page && !!perPage) {
        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage;

        range = JSON.stringify([rangeStart, rangeEnd]);
      }

      if (params.filter) {
        if (Object.keys(params.filter).length > 0) {
          Object.entries(params.filter).forEach(([key, value]) =>
            set(filters, key, value)
          );
        }
      }

      const query = {
        sort,
        range,
        filter: JSON.stringify(filters),
      };

      search = `?${stringify(query, { skipNulls: false })}`;
    }

    const { data, meta } = await fetch(`/${resource}${search}`);

    return {
      data,
      total: meta?.paging?.total_items,
    };
  },
  getOne: async (resource, params) => {
    const { data, errors } = await fetch(`/${resource}/${params.id}`);

    if (Array.isArray(errors) && errors.length > 0) {
      return Promise.reject();
    }

    const [first] = data;

    return {
      data: first,
    };
  },
  getMany: async (resource, params) => {
    const data = await Promise.all(
      params.ids.map(async (id) => {
        const { data } = await fetch(`/${resource}/${id}`);

        return data[0];
      })
    );

    return {
      data,
    };
  },
  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };

    const { data, meta } = await fetch(`/${resource}?${stringify(query)}`);

    return {
      data,
      total: meta?.total || data?.length,
    };
  },
  update: async (resource, params) => {
    const { files } = await uploadFile(params);

    const { data, errors } = await fetch(`/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        // ...difference(params.previousData, params.data),
        ...params.data,
        ...files,
      }),
    });

    if (Array.isArray(errors) && errors.length > 0) {
      return Promise.reject();
    }

    const [first] = data;

    return {
      data: first,
    };
  },
  updateMany: async (resource, params) => {
    const data = await Promise.all(
      params.ids.map(async (id) => {
        const { files } = await uploadFile(params);

        const { data } = await fetch(`/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify({
            ...params?.data,
            ...files,
          }),
        });

        return data.id;
      })
    );

    return {
      data,
    };
  },
  create: async (resource, params) => {
    const { files } = await uploadFile(params);

    const { data, errors } = await fetch(`/${resource}`, {
      method: 'POST',
      body: JSON.stringify({
        ...params?.data,
        ...files,
      }),
    });

    if (Array.isArray(errors) && errors.length > 0) {
      return Promise.reject();
    }

    const [first] = data;

    return {
      data: first,
    };
  },
  delete: async (resource, params) => {
    const { data, errors } = await fetch(`/${resource}/${params.id}`, {
      method: 'DELETE',
    });

    if (Array.isArray(errors) && errors.length > 0) {
      return Promise.reject();
    }

    const [first] = data;

    return {
      data: first,
    };
  },
  deleteMany: async (resource, params) => {
    const data = await Promise.all(
      params.ids.map(async (id) => {
        const { data } = await fetch(`/${resource}/${id}`, {
          method: 'DELETE',
        });

        return data.id;
      })
    );

    return {
      data,
    };
  },
};
