import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import { useStyles } from './styles';

export const Layout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item lg={4} md={4} sm={12} xs={12} className={classes.container}>
        {children}
      </Grid>
      <Grid item lg={8} md={8} sm={false} className={classes.main}></Grid>
    </Grid>
  );
};

Layout.displayName = 'Layout';
export default Layout