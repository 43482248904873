import React from 'react';
import { FunctionField } from 'react-admin';
import { Typography } from '@material-ui/core';

import { orderStatuses } from 'constants/orders';

type OrderStatusFieldProps = {
  source?: string;
  label?: string;
};

type OrderStatusFieldComponent = React.FC<OrderStatusFieldProps>;

const colorsByStatus = {
  succeeded: {
    label: '#077E55',
    background: '#D5F9D3',
  },
  pending: {
    label: '#F3BB25',
    background: '#FCF0CF',
  },
};

export const OrderStatusField: OrderStatusFieldComponent = ({
  source,
  label,
  ...props
}) => (
  <FunctionField
    {...props}
    source={source}
    label={label}
    render={(record) => (
      <Typography
        style={{
          alignItems: 'center',
          backgroundColor:
            colorsByStatus[record?.order_status?.name]?.background,
          borderRadius: 4,
          color: colorsByStatus[record?.order_status?.name]?.label,
          height: 25,
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        {orderStatuses[record?.order_status?.name]}
      </Typography>
    )}
  />
);

OrderStatusField.displayName = 'OrderStatusField';
