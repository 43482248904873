import React from 'react';

const properties = {
  list: React.lazy(
    () => import(/* webpackChunkName: "Requirements.list" */ './List')
  ),
  edit: React.lazy(
    () => import(/* webpackChunkName: "Requirements.edit" */ './Edit')
  ),
  show: React.lazy(
    () => import(/* webpackChunkName: "Requirements.show" */ './Show')
  ),
  create: React.lazy(
    () => import(/* webpackChunkName: "Requirements.create" */ './Create')
  ),
  options: {},
};

export default properties;
