import { useEffect, useState, useRef } from 'react';
import { useDataProvider } from 'react-admin';
import isValid from 'date-fns/isValid';

export const useDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [metrics, setMetrics] = useState(null);
  const [error, setError] = useState(null);
  const fromRef = useRef<Date>(new Date());
  const toRef = useRef<Date>(new Date());

  const dataProvider = useDataProvider();

  const TIME_IN_MILLIS = 5000;

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const {
          data: [first],
        } = await dataProvider.getList('overview', {
          filter: {
            from: isValid(fromRef.current) ? fromRef.current : new Date(),
            to: isValid(toRef.current) ? toRef.current : new Date(),
          },
        } as any);

        setMetrics(first);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }, TIME_IN_MILLIS);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    metrics,
    error,
    fromRef,
    toRef,
    hasFilters: !!(fromRef.current || toRef.current),
  };
};
