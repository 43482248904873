import React from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Chip,
  Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStyles } from './styles';

export const DashboardCardItem = ({
  label,
  labelColor,
  labelBackgroundColor,
  title,
  actionTitle,
  actionButton = null,
  isLoading,
}: any) => {
  const classes = useStyles({
    labelBackgroundColor,
    labelColor,
  });

  return (
    <Card variant="outlined" style={{ height: 165 }}>
      <CardContent>
        <Chip label={label} className={classes.cardChip} />
        <Typography variant="h5" gutterBottom paragraph>
          {isLoading ? <Skeleton height={32} width={120} /> : title}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Typography variant="body1" color="textSecondary">
          {actionTitle}
        </Typography>
        {actionButton}
      </CardActions>
    </Card>
  );
};

DashboardCardItem.displayName = 'DashboardCardItem';
