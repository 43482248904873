import spanishMessages from '@blackbox-vision/ra-language-spanish';

export const esMessages = {
  ...spanishMessages,
  resources: {
    overview: {
      name: 'Overview',
      fields: {
        created_at: 'Fecha de creación',
      },
    },
    packages: {
      name: 'Paquetes',
      fields: {
        name: 'Nombre',
        cover_url: 'Imagen',
        promo_video_id: 'URL del video',
        promo_video_url: 'URL del video',
        description: 'Descripción',
        price: 'Precio unitario',
        discount: 'Descuento',
        real_price: 'Total',
        created_at: 'Fecha de creación',
        published: 'Publicado',
        programs: 'Programas',
        'package-benefits': 'Beneficios',
        requirements: 'Requisitos',
      },
    },
    programs: {
      name: 'Programas',
      fields: {
        name: 'Nombre',
        cover_url: 'Imagen',
        created_at: 'Fecha de creación',
        description: 'Descripción',
        categories: 'Categorías',
        category_id: 'Nombre',
        tutorials: 'Tutoriales',
      },
    },
    categories: {
      name: 'Categorias',
      fields: {
        name: 'Nombre',
        internal_name: 'Nombre interno',
        equipment: 'Equipamiento',
        index: 'Orden',
        subtitle: 'Subtítulo',
        program_id: 'Programa',
        cover_url: 'Imagen',
        created_at: 'Fecha de creación',
        sections: 'Secciones',
      },
    },
    sections: {
      name: 'Secciones',
      fields: {
        name: 'Nombre',
        created_at: 'Fecha de creación',
        routines: 'Rutinas',
        section_id: 'Nombre',
      },
    },
    routines: {
      name: 'Rutinas',
      fields: {
        name: 'Nombre',
        internal_name: 'Nombre interno',
        created_at: 'Fecha de creación',
        equipment: 'Equipamiento',
        category_id: 'Categoría',
        section_id: 'Sección',
        exercises: 'Ejercicios',
        'unit_type.name': 'Unidad',
        repetitions: 'Cantidad',
        unit_type_id: 'Unidad',
        series: 'Series',
        rest_time: 'Descanso',
        add_button: 'Agregar ejercicio',
        exercise: {
          name: 'Nombre',
        },
        'sets.exercises': 'Ejercicios',
      },
    },
    exercises: {
      name: 'Ejercicios',
      fields: {
        name: 'Nombre',
        created_at: 'Fecha de creación',
        cover_url: 'Imagen',
        description: 'Descripción',
        video_id: 'URL del video',
        video_url: 'Video',
      },
    },
    'super-sets': {
      name: 'SuperSets',
      fields: {
        name: 'Nombre',
        created_at: 'Fecha de creación',
        exercises: 'Ejercicios',
        series: 'Series',
        rest_time: 'Descanso',
        repetitions: 'Cantidad',
        unit_type_id: 'Unidad',
        exercise_id: 'Nombre',
      },
    },
    orders: {
      name: 'Pedidos',
      fields: {
        created_at: 'Fecha de creación',
        number: 'Número',
        user_id: 'Usuario',
        package_id: 'Paquete',
        real_price: 'Total',
        user: {
          firstname: 'Nombre',
          lastname: 'Apellido',
        },
        package: {
          id: 'Paquete',
        },
        payment_platform: {
          id: 'Medio de pago',
        },
      },
    },
    users: {
      name: 'Usuarios',
      fields: {
        avatar: 'Avatar',
        fullname: 'Nombre completo',
        created_at: 'Fecha de creación',
        firstname: 'Nombre',
        lastname: 'Apellido',
        email: 'Email',
        password: 'Contraseña',
        enabled: 'Habilitado',
        role_id: 'Rol',
      },
    },
    requirements: {
      name: 'Requisitos',
      fields: {
        name: 'Nombre',
        created_at: 'Fecha de creación',
        cover_url: 'Imagen',
      },
    },
    'package-benefits': {
      name: 'Beneficios',
      fields: {
        name: 'Nombre',
        created_at: 'Fecha de creación',
        cover_url: 'Imagen',
      },
    },
    tutorials: {
      name: 'Tutoriales',
      fields: {
        name: 'Nombre',
        created_at: 'Fecha de creación',
        cover_url: 'Imagen',
        video_url: 'Video',
        video_id: 'Nombre del video',
        program_id: 'Programa',
      },
    },
  },
};
