import React, { FC } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

interface FromToDateGroupProps {
  fromRef: any;
  toRef: any;
  hasFilters: boolean;
}

const FromToDateGroup: FC<FromToDateGroupProps> = ({
  fromRef,
  toRef,
  hasFilters,
}) => {
  return (
    <Grid style={{ margin: 8 }} container>
      <Grid item>
        <KeyboardDatePicker
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="dense"
          label="Desde"
          value={fromRef.current}
          onChange={(value) => (fromRef.current = value)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          invalidDateMessage="Fecha inválida"
          maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
          minDateMessage="La fecha no debe ser anterior a la fecha mínima"
          disableToolbar
          autoOk
        />
      </Grid>
      <Grid item>
        <KeyboardDatePicker
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="dense"
          label="Hasta"
          value={toRef.current}
          onChange={(value) => (toRef.current = value)}
          minDate={fromRef.current}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          invalidDateMessage="Fecha inválida"
          maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
          minDateMessage="La fecha no debe ser anterior a la fecha mínima"
          disableToolbar
          autoOk
        />
      </Grid>
      {hasFilters && (
        <Grid item>
          <Tooltip title="Limpiar filtros">
            <IconButton
              onClick={() => {
                fromRef.current = null;
                toRef.current = null;
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

FromToDateGroup.displayName = 'FromToDateGroup';

export default FromToDateGroup;
