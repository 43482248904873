import {
  AttachMoneyOutlined,
  AllInboxOutlined,
  PersonOutline,
  InsertChartOutlined,
  ListOutlined,
  LocalOfferOutlined,
  AssignmentOutlined,
  AccessibilityOutlined,
} from '@material-ui/icons';

const overview = {
  type: 'resource',
  name: '',
  icon: InsertChartOutlined,
  label: 'Overview',
};

const catalog = {
  type: 'group',
  label: 'Catálogo',
  items: [
    {
      type: 'resource',
      name: 'packages',
      icon: AllInboxOutlined,
      label: 'Paquetes',
    },
    {
      type: 'resource',
      name: 'programs',
      icon: ListOutlined,
      label: 'Programas',
      roles: ['admin'],
    },
    {
      type: 'resource',
      name: 'categories',
      icon: LocalOfferOutlined,
      label: 'Categorias',
      roles: ['admin'],
    },
    {
      type: 'resource',
      name: 'routines',
      icon: AssignmentOutlined,
      label: 'Rutinas',
      roles: ['admin'],
    },
    {
      type: 'resource',
      name: 'exercises',
      icon: AccessibilityOutlined,
      label: 'Ejercicios',
      roles: ['admin'],
    },
  ],
};

const sales = {
  type: 'group',
  label: 'Ventas',
  items: [
    {
      type: 'resource',
      name: 'orders',
      icon: AttachMoneyOutlined,
      label: 'Pedidos',
    },
  ],
};

const users = {
  type: 'resource',
  name: 'users',
  icon: PersonOutline,
  label: 'Usuarios',
};

export const getMenuItems = () => {
  return [overview, catalog, sales, users];
};
