import firebase from 'firebase/app';
import 'firebase/auth';
import { AuthProvider } from 'react-admin';

import { fetch } from 'utils/fetch';
import { Roles } from 'constants/roles';
import * as FirebaseHelpers from 'utils/firebase';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await firebase.auth().signInWithEmailAndPassword(username, password);

    const response = await fetch(`/auth/me`);

    const [user] = response.data;

    if (user?.role?.name !== Roles.ADMIN) {
      return Promise.reject({
        redirectTo: '/login',
      });
    }

    localStorage.setItem('@pro-athletes/user', JSON.stringify(user));
    localStorage.setItem('@pro-athletes/role', user?.role?.name);

    return await FirebaseHelpers.getUser();
  },
  logout: async () => {
    const result = await firebase.auth().signOut();

    localStorage.removeItem('@pro-athletes/user');
    localStorage.removeItem('@pro-athletes/role');

    return result;
  },
  checkAuth: async () => {
    const token = await FirebaseHelpers.getToken();

    const role = localStorage.getItem('@pro-athletes/role');

    if (role !== Roles.ADMIN) {
      return Promise.reject({
        redirectTo: '/login',
      });
    }

    return !!token
      ? Promise.resolve()
      : Promise.reject({
          redirectTo: '/login',
        });
  },
  checkError: async (error) => {
    const status = error.status;

    if (status === 401) {
      await firebase.auth().signOut();

      localStorage.removeItem('@pro-athletes/user');
      localStorage.removeItem('@pro-athletes/role');

      return Promise.reject({
        redirectTo: '/login',
      });
    }
  },
  getIdentity: async () => {
    try {
      const user = JSON.parse(localStorage.getItem('@pro-athletes/user'));

      return Promise.resolve({
        id: user?.id,
        avatar: user?.avatar,
        fullName: `${user?.firstname} ${user?.lastname}`,
      });
    } catch (error) {
      return Promise.reject({
        redirectTo: '/login',
      });
    }
  },
  getPermissions: async (params: any) => {
    const role = localStorage.getItem('@pro-athletes/role');

    return !!role ? Promise.resolve(role) : Promise.reject();
  },
  recoverPassword: async (email: string) => {
    const { data, errors } = await fetch('/auth/password-reset', {
      method: 'POST',
      body: JSON.stringify({ email }),
    });

    if (Array.isArray(errors) && errors.length > 0) {
      return Promise.reject();
    }

    const [first] = data;

    return {
      data: first,
    };
  },
};
